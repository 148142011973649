<template>
  <CForm @submit.prevent="checkForm" method="POST">
    <CCard>
      <CCardBody>
        <h3>{{ operationName }} Customer</h3>
        <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>

        <CRow>
          <CCol sm="3">
            <CInput
              label="First Name"
              type="text"
              v-model="customer.firstName"
            ></CInput
          ></CCol>
          <CCol sm="3">
            <CInput
              label="Last Name"
              type="text"
              v-model="customer.lastName"
            ></CInput
          ></CCol>
          <CCol sm="3">
            <CInput label="Email" type="text" v-model="customer.email"></CInput
          ></CCol>
          <CCol sm="3">
            <CInput
              label="Mobile number"
              type="text"
              v-model="customer.mobileNumber"
            ></CInput
          ></CCol>
          <CCol sm="3">
            <CInput
              label="Company"
              type="text"
              v-model="customer.company"
            ></CInput
          ></CCol>
          <CCol sm="3">
            <CSelect
              label="Company Account"
              :value.sync="customer.companyAccount"
              :plain="true"
              :options="companies"
            >
            </CSelect>
          </CCol>
          <CCol sm="3">
            <CInput
              label="Vat Number"
              type="text"
              v-model="customer.vatNumber"
            ></CInput
          ></CCol>
        </CRow>

        <CRow>
          <CCol col="3">
            <CSelect
              label="Gender"
              :options="genders"
              :value.sync="customer.gender"
            >
            </CSelect>
          </CCol>
          <CCol col="3">
            <label>Date of birth</label>
            <ejs-datepicker
              v-model="customer.dateOfBirthSimple"
              :format="$dateFormatInput.split(' ')[0]"
              :value="customer.dateOfBirthSimple"
            ></ejs-datepicker>
          </CCol>

          <CCol sm="3">
            <CSelect
              label="Status"
              :options="statuses"
              :value.sync="customer.status"
            ></CSelect>
          </CCol>

          <CCol sm="3">
            <div><label>Consent to marketing</label></div>
            <CSwitch
              color="success"
              size="lg"
              :checked.sync="customer.consentToMarketing"
            ></CSwitch>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="3">
            <div><label>Has disabilities</label></div>
            <CSwitch
              color="success"
              size="lg"
              :checked.sync="customer.hasDisabilities"
            ></CSwitch>
          </CCol>
          <CCol sm="3">
            <div><label>Picture</label></div>
            <input
              id="file"
              ref="file"
              type="file"
              @change="handleFileUpload"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12">
            <br />
            <br />
            <div>
              <img
                v-if="customer.picture"
                :src="customer.picture"
                class="img-thumbnail"
                style="max-height: 200px"
              />
            </div>
          </CCol>
        </CRow>

        <br />
        <br />
        <CRow>
          <CCol col="7"></CCol>
          <CCol class="d-flex justify-content-end">
            <CButton
              color="secondary"
              class="btn-lg"
              @click="goBack"
              style="margin-right: 15px"
              ><CIcon name="cil-list" /> Back to the list
            </CButton>

            <CLoadingButton
              :disabled="loading"
              color="primary"
              type="submit"
              class="btn-lg"
              ><CIcon name="cil-save" /> Save</CLoadingButton
            >

            <CButton
              color="success"
              v-if="customer.id != emptyGuid"
              @click="changePasswordConfirmationModal = true"
              style="margin-right: 15px"
              class="btn"
            >
              <CIcon name="cilLockLocked" />
              Change customer password
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
        <br />
        <br />
        <br />
        <CRow>
          <CCol>
            <CButton
              color="success"
              v-if="customer.id != emptyGuid"
              @click="
                addressModal = true;
                addressModalType = 'success';
                addressModalAction = 'add';
                addressModalTitle = 'Add new address';
              "
              style="margin-right: 15px"
              class="btn"
            >
              <CIcon name="cil-location-pin" />
              Add address
            </CButton>
          </CCol>
        </CRow>
        <br />

        <CModal
          title="Are you sure to reset customer password?"
          color="success"
          :show.sync="changePasswordConfirmationModal"
          @update:show="closeResetConfirmationModal"
        >
          <CRow>
            <CCol col="12">
              <span>
                You are about to reset password for {{ customer.firstName }}
                {{ customer.lastName }}. Please verify {{ customer.email }}.
                After confirming your action please do not close this window.
              </span>
            </CCol>
          </CRow>
        </CModal>

        <CModal
          title="Please insert required details"
          color="success"
          :show.sync="provideTokenModal"
          @update:show="resetPassword"
        >
          <CRow>
            <CCol col="12">
              <CRow>
                <CCol col="7">
                  <CInput
                    label="Email Token"
                    v-model="resetPasswordModel.token"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="7">
                  <CInput
                    label="New Password"
                    type="password"
                    v-model="resetPasswordModel.newPassword"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="7">
                  <CInput
                    label="New Password"
                    type="password"
                    v-model="resetPasswordModel.newPasswordConfirmed"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CModal>

        <CModal
          v-model="address.id"
          :title="addressModalTitle"
          :color="addressModalType"
          :show.sync="addressModal"
          @update:show="closeAddressModal"
        >
          <CRow>
            <CCol col="4">
              <CInput
                label="Street Address 1"
                v-model="address.streetAddress1"
              />
            </CCol>
            <CCol col="4">
              <CInput
                label="Street Address 2"
                v-model="address.streetAddress2"
              />
            </CCol>

            <CCol col="4">
              <CInput label="Company" v-model="address.company" />
            </CCol>
            <CCol col="4">
              <CInput label="Postal code" v-model="address.postalCode" />
            </CCol>

            <CCol col="4">
              <CInput label="City" v-model="address.city" />
            </CCol>
            <CCol col="4">
              <CSelect
                label="Country"
                :value.sync="address.country"
                :plain="true"
                :options="countries"
              >
              </CSelect>
            </CCol>
            <CCol col="4">
              <CInput label="State province" v-model="address.stateProvince" />
            </CCol>
            <CCol col="4">
              <CInput label="Vat number" v-model="address.vatNumber" />
            </CCol>
            <CCol col="4">
              <CSelect
                label="Type"
                :value.sync="address.type"
                :plain="true"
                :options="types"
              >
              </CSelect>
            </CCol>
            <CCol col="4">
              <CInputCheckbox
                label="is default"
                :checked.sync="address.isDefault"
              />
            </CCol>
          </CRow>
        </CModal>

        <CDataTable
          :items="customer.addresses"
          :fields="addressFields"
          hover
          v-if="customer.id != emptyGuid && customer.addresses.length > 0"
        >
          <template #name="{ item }">
            <td>
              <strong>{{ item.name }}</strong>
            </td>
          </template>
          <template #EDIT="{ item }">
            <td style="width: 15%">
              <CButton
                color="primary"
                @click="
                  addressModalTitle = 'Edit address';
                  addressModal = true;
                  addressModalAction = 'add';
                  addressModalType = 'info';
                  address = item;
                "
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #DELETE="{ item }">
            <td style="width: 15%">
              <CButton
                color="danger"
                @click="
                  modalTitle = 'Are You sure to delete address ?';
                  addressModalAction = 'delete';
                  schemaId = item.id;
                  addressModal = true;
                  addressModalType = 'danger';
                  address = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>

        <CModal
          v-model="guest.id"
          :title="guestModalTitle"
          :color="guestModalType"
          :show.sync="guestModal"
          @update:show="closeGuestModal"
        >
          <CRow>
            <CCol col="4">
              <CInput label="First name" v-model="guest.firstName" />
            </CCol>
            <CCol col="4">
              <CInput label="Last name" v-model="guest.lastName" />
            </CCol>

            <CCol col="4">
              <CSelect
                label="Gender"
                :value.sync="guest.gender"
                :plain="true"
                :options="genders"
              >
              </CSelect>
            </CCol>
            <CCol col="4">
              <CSelect
                label="Citizenship"
                :options="countries"
                :value.sync="guest.citizenship"
              >
              </CSelect>
            </CCol>

            <CCol sm="4">
              <CInput label="Email" type="text" v-model="guest.email"></CInput
            ></CCol>
            <CCol sm="4">
              <CInput
                label="Mobile number"
                type="text"
                v-model="guest.mobileNumber"
              ></CInput
            ></CCol>

            <CCol col="6">
              <label>Date of birth</label>
              <ejs-datepicker
                v-model="guest.dateOfBirthSimple"
                :format="$dateFormatInput.split(' ')[0]"
              ></ejs-datepicker>
            </CCol>

            <CCol sm="6">
              <div><label>Has disabilities</label></div>
              <CSwitch
                color="success"
                size="lg"
                :checked.sync="guest.hasDisabilities"
              ></CSwitch>
            </CCol>
          </CRow>
        </CModal>

        <br />
        <br />
        <CRow>
          <CCol>
            <CButton
              color="success"
              v-if="customer.id != emptyGuid"
              @click="
                guestModal = true;
                guestModalType = 'success';
                guestModalAction = 'add';
                guestModalTitle = 'Add new guest';
              "
              style="margin-right: 15px"
              class="btn"
            >
              <CIcon name="cil-user" />
              Add guest
            </CButton>
          </CCol>
        </CRow>
        <br />

        <CDataTable
          :items="customer.guests"
          :fields="guestFields"
          hover
          v-if="customer.id != emptyGuid && customer.guests.length > 0"
        >
          <template #name="{ item }">
            <td>
              <strong>{{ item.name }}</strong>
            </td>
          </template>

          <template #EDIT="{ item }">
            <td style="width: 15%">
              <CButton
                color="primary"
                @click="
                  guestModalTitle = 'Edit guest';
                  guestModal = true;
                  guestModalAction = 'add';
                  guestModalType = 'info';
                  guest = item;
                "
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #DELETE="{ item }">
            <td style="width: 15%">
              <CButton
                color="danger"
                @click="
                  modalTitle = 'Are You sure to delete guest ?';
                  guestModalAction = 'delete';
                  schemaId = item.id;
                  guestModal = true;
                  guestModalType = 'danger';
                  guest = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </CForm>
</template>

<script>
import axios from "axios";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
export default {
  name: "CreateOrUpdateCustomer",
  components: {
    "ejs-datepicker": DatePickerComponent,
  },
  data: () => {
    return {
      operationName: null,
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      customer: {
        id: "00000000-0000-0000-0000-000000000000",
        firstName: null,
        lastName: null,
        email: null,
        mobileNumber: null,
        company: null,
        vatNumber: null,
        consentToMarketing: true,
        picture: null,
        file: null,
        status: null,
        gender: null,
        dateOfBirthSimple: null,
        hasDisabilities: false,
        addresses: [],
        guests: [],
      },
      forgotPasswordModel: {
        email: null,
      },
      resetPasswordModel: {
        token: null,
        newPassword: null,
        newPasswordConfirmed: null,
      },
      //Alert
      message: null,
      alertType: "danger",
      changePasswordConfirmationModal: false,
      provideTokenModal: false,
      //address
      address: {
        id: "00000000-0000-0000-0000-000000000000",
        streetAddress1: null,
        streetAddress2: null,
        company: null,
        postalCode: null,
        city: null,
        country: null,
        stateProvince: null,
        vatNumber: null,
        type: null,
        isDefault: false,
      },

      types: [
        { label: "Choose", value: 0 },
        { label: "BillingAndShipping", value: 1 },
        { label: "Billing", value: 2 },
        { label: "Shipping", value: 3 },
      ],

      statuses: [
        { label: "Choose", value: 0 },
        { label: "Active", value: 1 },
        { label: "Blocked", value: 2 },
      ],

      addressFields: [
        "streetAddress1",
        "city",
        "type",
        "isDefault",
        "EDIT",
        "DELETE",
      ],
      addressModalTitle: null,
      addressModal: false,
      addressModalAction: null,
      addressModalType: "danger",

      //guest
      guestModalTitle: null,
      guestModal: false,
      guestModalAction: null,
      guestModalType: "danger",

      guest: {
        id: "00000000-0000-0000-0000-000000000000",
        firstName: null,
        lastName: null,
        gender: null,
        dateOfBirthSimple: null,
        citizenship: null,
        hasDisabilities: false,
        email: null,
        mobileNumber: null,
      },

      guestFields: [
        "firstName",
        "lastName",
        "gender",
        "email",
        "mobileNumber",
        "EDIT",
        "DELETE",
      ],

      genders: [
        { label: "NotSpecified", value: 4 },
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "NonBinary", value: 3 },
      ],
      countries: [],
      companies: [
        {
          label: "Choose",
          value: "00000000-0000-0000-0000-000000000000",
        },
      ],
      loading: false,
    };
  },
  methods: {
    checkForm: function (e) {
      if (this.loading) return;
      this.loading = true;
      if (
        !this.customer.firstName ||
        !this.customer.lastName ||
        !this.customer.mobileNumber ||
        !this.customer.email
      ) {
        this.failed = true;
        this.message = "Please enter all needed fields.";
        this.loading = false;
        this.alertType = "danger";
        setTimeout(function () {
          this.message = null;
        }, 10000);
        return;
      }
      this.save();
    },
    goBack() {
      this.$router.go(-1);
    },
    getCompanies() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Company")
        .then(function (response) {
          let mappedCompanies = response.data.map((company) => {
            return {
              label: company.name,
              value: company.id,
            };
          });
          self.companies.push(...mappedCompanies);
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    get(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/customer/${id}`)
        .then(function (response) {
          if (!response?.data) {
            self.$router.push({ path: "/customers" }); // if not getting data for this customer id, then go to list view
          } else {
            self.customer = response.data;
             self.customer.dateOfBirthSimple = new Date(
              self.customer.dateOfBirthSimple
            )
            let guestsTemporary = [];
            self.customer.guests.map(function (value, key) {
              value.dateOfBirthSimple = new Date(value.dateOfBirthSimple);
              guestsTemporary.push(value);
            });
            self.customer.guests = guestsTemporary;
          }
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    handleFileUpload(event) {
      this.customer.file = event.target.files[0];
    },
    save() {
      let self = this;
      let form = new FormData();
      form.append("id", self.customer.id);
      form.append("firstName", self.customer.firstName);
      form.append("lastName", self.customer.lastName);
      form.append("email", self.customer.email);
      form.append("mobileNumber", self.customer.mobileNumber);
      form.append(
        "company",
        self.customer.company == null ? "" : self.customer.company
      );
      form.append(
        "vatNumber",
        self.customer.vatNumber == null ? "" : self.customer.vatNumber
      );
      form.append("consentToMarketing", self.customer.consentToMarketing);
      form.append("picture", self.customer.picture);
      form.append("file", self.customer.file);
      if (
        self.customer.companyAccount &&
        self.customer.companyAccount != "00000000-0000-0000-0000-000000000000"
      ) {
        form.append("companyAccount", self.customer.companyAccount);
      }
      if (self.customer.status == 0) {
        self.customer.status = 2;
      }
      form.append("status", self.customer.status);
      form.append("gender", self.customer.gender);
      if (
        self.customer.dateOfBirthSimple &&
        self.customer.dateOfBirthSimple != "0000-12-31"
      ) {
        form.append("dateOfBirth", self.customer.dateOfBirthSimple);
      }
      form.append("hasDisabilities", self.customer.hasDisabilities);
      axios
        .post(`${this.$apiAdress}/v1/customer`, form)
        .then((response) => {
          self.customer.id = response.data;
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully updated customer.";
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });

      self.loading = false;
    },
    addAddress() {
      let self = this;
      if (!self.address.country || !self.address.type) {
        self.failed = true;
        self.message = "Please enter all needed fields.";
        self.loading = false;
        self.alertType = "danger";
        setTimeout(function () {
          self.message = null;
        }, 10000);
        return;
      }

      axios
        .post(
          `${this.$apiAdress}/v1/customer/CreateAddress/${self.customer.id}`,
          self.address
        )
        .then(function (response) {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully updated address.";
          self.get(self.customer.id);
          self.cleanAddress();
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });
    },
    getAddresses() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/customer/AddressList/" + self.customer.id)
        .then(function (response) {
          self.addresses = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    cleanAddress() {
      let self = this;
      self.address.id = "00000000-0000-0000-0000-000000000000";
      self.address.streetAddress1 = null;
      self.address.streetAddress2 = null;
      self.address.company = null;
      self.address.postalCode = null;
      self.address.city = null;
      self.address.country = null;
      self.address.stateProvince = null;
      self.address.vatNumber = null;
      self.address.type = null;
      self.address.isDefault = false;
    },
    closeResetConfirmationModal(status, evt, accept) {
      let self = this;
      if (accept) {
        this.forgotPasswordModel.email = this.customer.email;
        axios
          .post(
            `${this.$apiAdress}/v1/Account/ForgotPassword`,
            this.forgotPasswordModel
          )
          .then(function (response) {
            self.provideTokenModal = true;
            self.changePasswordConfirmationModal = false;
          })
          .catch(function (error) {});
      }
    },
    resetPassword(status, evt, accept) {
      let self = this;
      if (accept) {
        axios
          .post(
            `${this.$apiAdress}/v1/Account/ResetPassword`,
            this.resetPasswordModel
          )
          .then(function (response) {
            self.alertType = "success";
            setTimeout(function () {
              self.message = null;
            }, 5000);
            self.message = "Password successfully reset.";
            self.provideTokenModal = false;
            self.changePasswordConfirmationModal = false;
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
          });
      }
    },
    closeAddressModal(status, evt, accept) {
      if (accept) {
        if (this.addressModalAction == "add") {
          this.addAddress();
        } else if (this.addressModalAction == "delete") {
          this.deleteAddress(this.address.id);
        } else {
          this.addAddress();
        }
      }
    },
    deleteAddress(id) {
      let self = this;
      axios
        .delete(
          `${this.$apiAdress}/v1/customer/${self.customer.id}/Address/${id}`
        )
        .then(function (response) {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully deleted address.";
          self.cleanAddress();
          self.get(self.customer.id);
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    addGuest() {
      let self = this;
      if (
        !self.guest.firstName ||
        !self.guest.lastName ||
        !self.guest.gender ||
        !self.guest.citizenship ||
        !self.guest.email ||
        !self.guest.mobileNumber ||
        !self.guest.dateOfBirthSimple
      ) {
        self.failed = true;
        self.message = "Please enter all needed fields.";
        self.loading = false;
        self.alertType = "danger";
        setTimeout(function () {
          self.message = null;
        }, 10000);
        return;
      }

      axios
        .post(
          `${this.$apiAdress}/v1/customer/CreateGuest/${self.customer.id}`,
          self.guest
        )
        .then(function (response) {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully updated guest.";
          self.get(self.customer.id);
          self.cleanGuest();
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });
    },

    cleanGuest() {
      let self = this;
      self.guest.id = "00000000-0000-0000-0000-000000000000";
      self.guest.firstName = null;
      self.guest.lastName = null;
      self.guest.gender = null;
      self.guest.dateOfBirthSimple = null;
      self.guest.citizenship = null;
      self.guest.email = null;
      self.guest.mobileNumber = null;
      self.guest.hasDisabilities = false;
    },

    deleteGuest(id) {
      let self = this;
      axios
        .delete(
          `${this.$apiAdress}/v1/customer/${self.customer.id}/Guest/${id}`
        )
        .then(function (response) {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully deleted guest.";
          self.cleanGuest();
          self.get(self.customer.id);
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    closeGuestModal(status, evt, accept) {
      if (accept) {
        if (this.guestModalAction == "add") {
          this.addGuest();
        } else if (this.guestModalAction == "delete") {
          this.deleteGuest(this.guest.id);
        } else {
          this.addGuest();
        }
      }
    },

    getCountryList() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/CountryList")
        .then(function (response) {
          self.countries = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    let self = this;
    self.getCountryList();
    self.getCompanies();

    if (this.$route.params.id != this.emptyGuid) {
      self.operationName = "Edit";
      self.get(this.$route.params.id);
    } else {
      self.operationName = "Create New";
    }
  },
};
</script>
